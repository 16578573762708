<template>
	<div class="choose">
<!--		<p class="tip">使用地图打开</p>-->
		<p class="tips">如果点击无响应，可能是您还未安装该APP</p>
		<div class="list" v-for="(item, index) in mapList" :key = "index" @click="toMapApp(item.id)">
			{{item.name}}
		</div>
	</div>
</template>

<script>
import Utils from '../../utils'

const utils = new Utils()
	export default {
		name: 'openApp',
		props: {
			keyword: {
				type: String,
				default: ''
			},
      latitude: {
			  type: Number,
        default: null
      },
      longitude: {
        type: Number,
        default: null
      }
		},
		data() {
			return {
				mapList: [
					{id: 1, name: '高德地图'},
					{id: 2, name: '百度地图'},
					{id: 3, name: '腾讯地图'}
				]
			}
		},
		methods: {
			toMapApp(id) {
				if(id === 1) {
					if(utils.isAndroid()) {
						window.location.href = "androidamap://poi?sourceApplication=lsboot-industry-map&keywords=" + this.keyword
					} else {
						window.location.href = "iosamap://poi?sourceApplication=lsboot-industry-map&name=" + this.keyword
					}
				} else if(id === 2) {
				  window.location.href=`baidumap://map/marker?location=${this.latitude},${this.longitude}&title=${this.keyword}&content=${this.keyword}&src=lsboot-industry-map&coord_type=gcj02`
					// window.location.href = "baidumap://map/place/search?query=" + this.keyword + '&src=lsboot-industry-map'
				} else {
          window.location.href = "qqmap://map/search?keyword=" + this.keyword + '&referer=lsboot-industry-map'
          // window.location.href= `qqmap://map/geocoder?coord=${this.latitude},${this.longitude}&referer=lsboot-industry-map`
        }
			}
		}
	}
</script>

<style lang="less" scoped>
	.choose {
		background-color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
    z-index: 99;

		.tips {
			font-size: 12px;
			margin-bottom: 10px;
			color: #ccc;
			font-weight: 400;
		}

		.list {
			width: 100%;
			font-weight: 400;
			height: 40px;
			border-top: 1px solid #f3f3f3;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 16px;
			color: #323232;
		}
	}
</style>
