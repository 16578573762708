<template>
	<div class="">
		<div class="bg"></div>
		<div class="top-content">
			<div class="detail">
				<div class="company">
          <img :src="data.orgLogo" alt=""/>
          <div class="title">
            <span>{{ data.orgName }}</span>
            <div class="type-list">
              <div class="title-bottom" v-for="(item ,index) in data.orgTypeList" :key="index">
                <img :src="item === '1' ? machining : item === '2' ? intelligent : buisness" alt=""/>
                <span>{{ item === '1' ? '大板云工厂' : item === '2' ? '成品云工厂' : '材料商' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tags-list" v-if="data.labelList.length">
          <div class="tags" v-for="(item, index) in data.labelList" :key="index">
            {{ item.labelName }}
          </div>
        </div>
        <div class="interal">
          <div class="line"></div>
          <span>品质保证</span>
          <div class="line"></div>
        </div>
        <div class="image">
          <div class="imageList" v-for="(item, index) in imageList" :key="index">
            <div class="img">
              <img :class="{img1 : index === 1}" :src="item.img" alt=""/>
            </div>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="persion">
          <div class="information">
            <div class="name">
              <span>{{ data.contactName }}</span>
              <span class="interals">|</span>
              <span>{{ data.contactPhone }}</span>
            </div>
            <div class="address">
              <img src="../../assets/dingwei@2x.png" alt=""/>
              <span>{{ data.orgAddr }}</span>
            </div>
          </div>
          <div class="action">
            <span @click="toGo">导航</span>
            <span class="interals">|</span>
            <span @click="toMap">地图</span>
          </div>
        </div>
      </div>
      <div class="card card1" v-if="data.orgImageList.length">
        <span>企业介绍</span>
        <el-carousel class="image image1" trigger="click" height="144px">
          <el-carousel-item v-for="item in data.orgImageList" :key="item">
            <img class="img" :src="item" alt=""/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="card" v-if="data.description">
        <span>生产介绍</span>
        <div class="image" v-html="data.description"></div>
      </div>
		</div>
		<img class="phoneCall" @click="toPhone" src="../../assets/phoneCall@2x.png" alt="" />
    <el-dialog :visible.sync="showPopup" title="使用地图打开" :show-close="false" center>
      <open-app :keyword="data.orgAddr"></open-app>
    </el-dialog>
	</div>
</template>

<script>
import openApp from "../../components/open-map-app/index.vue"
import Api from '../../api'

const api = new Api()
export default {
  components: {
    openApp
  },
  data() {
    return {
      showPopup: false,
      id: this.$route.query.orgId,
      data: {
        labelList: [],
        orgImageList: []
      },
      // 大板云工厂
      intelligent: require('../../assets/icon_intelligent@2x.png'),
      // 成品云工厂
      machining: require('../../assets/icon_machining@2x.png'),
      // 材料商
      buisness: require('../../assets/icon_buisness@2x.png'),
      imageList: [
        {
          img: require('../../assets/renzheng@2x.png'),
          name: '认证工厂'
					},
					{
            img: require('../../assets/old@2x.png'),
            name: '智能制造'
          },
					{
						img: require('../../assets/zizhi@2x.png'),
						name: '资质许可'
					},
				]
			}
		},
		mounted() {
      this.getData()
		},
		methods: {
			// 打电话
			toPhone() {
        window.location.href = 'tel://' + this.data.contactPhone
			},
      getData() {
        const param = {
          orgId: this.id
        }
        api.getOrgInfo(param).then(res => {
          this.data = res.data
          const list = res.data.orgTypeList.join(' ')
          list.indexOf('1') !== -1 ? this.data.orgType = 1 : list.indexOf('2') !== -1 ? this.data.orgType = 2 :  this.data.orgType = 3
        })
      },
			// 导航
			toGo() {
        this.showPopup = !this.showPopup
			},
			toMap() {
        this.$router.push({
          path: "/map",
          query: {
            orgId: this.data.orgId,
            orgType: this.data.orgType,
            latitude: this.data.lat,
            longitude: this.data.lng
          }
        });
			}
		}
	}
</script>
<style>
.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-dialog {
  margin-top: 0 !important;
  border-radius: 10px !important;
  width: 80% !important;
}
.el-dialog__title {
  font-size: 18px;
  line-height: 18px !important;
  color: #333 !important;
  margin-bottom: 10px;
  font-weight: 600;
}
.el-dialog__body {
  padding-top: 0 !important;
}

.el-message-box {
  width: 300px !important;
}

.el-carousel__item {
  height: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="less" scoped>
	.phoneCall {
    width: 66px;
    height: 66px;
    position: absolute;
    right: 0;
    bottom: 80px;
    z-index: 10;
  }
	.action {
		display: flex;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: #02AF66;
		line-height: 20px;

		.interals {
			margin: 0 5px;
			color: #DCDEE0;
		}
	}
	.bg {
		width: 100%;
		height: 125px;
		background: #02AF66;
	}
	.top-content {
		margin-top: -105px;
		padding: 0 20px 36px 20px;

		.detail {
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      padding: 15px 12px 3px 15px;
      border-radius: 6px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

      .company {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          border: 1px solid #ebedf0;
					margin-right: 10px;
				}

				.title {
          width: calc(100% - 75px);
          overflow-x: hidden;
          display: flex;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          flex-direction: column;

          .type-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            margin-top: 10px;
            flex-direction: row;
          }

          .title-bottom {
            margin-right: 5px;
            max-width: 82px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            border-radius: 2px;
            background: #F7F8FA;
            padding: 1px;
            align-items: center;
            font-size: 12px;
            color: #ccc;
            font-weight: 400;

            &:last-child {
              margin-right: 0;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }
          }
				}
			}
			.tags-list {
				margin-top: 10px;
				display: flex;
				justify-content: flex-start;
				flex-direction: row;
				align-items: center;
				width: 100%;
				flex-wrap: wrap;

				.tags {
					color: #666;
					font-size: 12px;
					padding: 0 5px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					line-height: 12px;
					margin-right: 5px;
					background: #FFFFFF;
					border-radius: 2px;
					border: 1px solid #DCDEE0;
				}
			}
			.interal {
				margin-top: 20px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
				font-size: 12px;
				line-height: 17px;
				color: #333;
				font-weight: normal;

				span {
					margin: 0 9px;
				}

				.line {
					width: calc((100% - 70px)/2);
					height: 1px;
					background-color: #ebedf0;
				}
			}
			.image {
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 10px;

				.imageList {
					font-size: 12px;
					line-height: 17px;
					font-weight: 400;
					color: #333;
					// width: 50px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.img {
						display: flex;
						align-items: center;
						margin-bottom: 5px;
						width: 30px;
						height: 30px;

						img {
							width: 100%;
							height: 100%;
						}

						.img1 {
							width: 28px !important;
							height: 24px !important;
						}
					}
				}
			}
			.persion {
        padding-bottom: 8px;
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				img {
					width: 48px;
					height: 48px;
				}

				.information {
					display: flex;
					flex-direction: column;
					width: calc(100% - 85px);

					.name {
            font-size: 14px;

            font-weight: 400;
            color: #333333;
            line-height: 20px;
            display: flex;
            flex-direction: row;

            span:first-child {
              -webkit-line-clamp: 1;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
            }

            .interals {
              margin: 0 10px;
            }
          }
					.address {
						margin-top: 5px;
						font-size: 12px;
						font-weight: 400;
						color: #999999;
						line-height: 17px;
						display: flex;
						align-items: center;
						justify-content: flex-start;

						span {
							width: calc(100% - 13px);
              -webkit-line-clamp: 1;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
						}

						img {
							width: 10px;
							height: 12px;
							margin-right: 3px;
						}
					}
				}
			}
		}
		.card {
			margin-top: 30px;
			font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;

      .image {
        margin-top: 10px;
        width: 100%;
        padding-bottom: 10px;
      }
    }

    .card1 {

      .image1 {

        .img {
          max-width: 100%;
          max-height: 154px;
        }
      }
    }
  }
</style>
